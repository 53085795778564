import React from "react";

// import css and other
import { STRING } from "../../config/string";
import ScrollAnimation from "react-animate-on-scroll";
import ROUTE_URLS, { BASE_URL } from "../../config/routes";

const OurTechnology = () => {
  return (
    <>
      {/* destop view start */}
      <div id="technology" className="hidden tech1  lg:block md:block sm:hidden md:px-[19px] 2xl:block m-auto lg:max-w-[1080px] lg:w-full xl:max-w-[1080px] pt-20 text-white">
        <ScrollAnimation
          animateIn="fadeInUp"
          scrollableParentSelector="#parent-scroll-bar"
        >
          <h1
            className="text-primary_active_color text-[36px]"
            dangerouslySetInnerHTML={{ __html: STRING.OUR_TECHNOLOGY }}
          />
        </ScrollAnimation>
        <div className="relative z-[2]">
          <ScrollAnimation
            animateIn="fadeInUp"
            scrollableParentSelector="#parent-scroll-bar"
          >
            <img
              src={BASE_URL+"img/tech_background.png"}
              alt="Background"
              className="w-full h-[1098px]"
            />
          </ScrollAnimation>

          <div>
            <div className="absolute top-0 left-0 right-0 grid grid-cols-2 w-full min-h-[300px] mt-[2rem] z-10">
              <ScrollAnimation
                animateIn="fadeInLeft"
                scrollableParentSelector="#parent-scroll-bar"
              >
                <div className="mb-16 flex flex-col h-full justify-start mr-[5rem]">
                  <h1
                    className="text-[36px] w-full leading-[44px] mb-[17px] font-bold"
                    dangerouslySetInnerHTML={{
                      __html: STRING.CONNECT_TO_BLOOMBERG_EMSX,
                    }}
                  />

                  <span
                    className="text-primary_color text-[18px]"
                    dangerouslySetInnerHTML={{
                      __html: STRING.EXECUTION_MANAGEMENT_SYSTEM_DEC,
                    }}
                  />
                </div>
              </ScrollAnimation>
              <div className="flex items-center justify-center">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  scrollableParentSelector="#parent-scroll-bar"
                >
                  <img
                    src={BASE_URL+"img/bloomberg.svg"}
                    alt="Bloomberg"
                  />
                </ScrollAnimation>
              </div>
            </div>
            <img
              src={BASE_URL+"img/mobile_share.png"}
              alt="Share"
              className="absolute top-[-65px] right-[-18px] z-[-1]"
            />
          </div>

          <div>
            <img
              src={BASE_URL+"img/mobile_share2.png"}
              alt="Share"
              className="absolute left-[-30px] top-[19rem]  z-[-1]"
            />

            <div className="absolute top-[22rem] left-0 right-0 grid grid-cols-2 w-full min-h-[324px] mt-[2rem] z-10">
              <div className="flex justify-center items-center">
                <img
                  src={BASE_URL+"img/redi_logo.svg"}
                  alt="Bloomberg"
                />
              </div>
              <ScrollAnimation
                animateIn="fadeInRight"
                scrollableParentSelector="#parent-scroll-bar"
              >
                <div className="ml-[5rem] flex flex-col justify-center h-full">
                  <h1
                    className="text-[36px] leading-[44px] mb-[17px] font-bold"
                    dangerouslySetInnerHTML={{
                      __html: STRING.SIMPLIFY_YOUR_WORKFLOW,
                    }}
                  />

                  <span
                    className="text-primary_color text-[18px]"
                    dangerouslySetInnerHTML={{
                      __html: STRING.THE_BROKER_NEUTRAL_DEC,
                    }}
                  />
                </div>
              </ScrollAnimation>
            </div>
          </div>

          <div>
            <div className="absolute top-[44rem] left-0 right-0 grid grid-cols-2 w-full min-h-[367px] mt-[2rem] z-10">
              <ScrollAnimation
                animateIn="fadeInLeft"
                scrollableParentSelector="#parent-scroll-bar"
              >
                <div className="flex flex-col mr-[5rem] h-full justify-center">
                  <h1
                    className="text-[36px] leading-[44px] mb-[17px] font-bold"
                    dangerouslySetInnerHTML={{
                      __html: STRING.GET_EFFICIENT_EXECUTION,
                    }}
                  />

                  <span
                    className="text-primary_color text-[18px]"
                    dangerouslySetInnerHTML={{
                      __html: STRING.DAS_SYSTEMS_PROVIDE_DES,
                    }}
                  />
                </div>
              </ScrollAnimation>
              <div className="flex items-center justify-center">
                <img
                  src={BASE_URL+"img/dastrader.svg"}
                  alt="Bloomberg"
                />
              </div>
            </div>
            <img
              src={BASE_URL+"img/mobile_share3.png"}
              alt="Share"
              className="absolute right-[-40px] bottom-[-5rem] z-4"
            />
          </div>
        </div>
      </div>
      {/* destop view end */}
      <div id="technology_mobile" className="py-[30px] pb-0 tech2 block md:hidden xl:hidden 2xl:hidden lg:hidden sm:block  px-[19px]">
        <h1
          className="text-primary_active_color mb-[55px] techTitle px-[19px] text-[36px]"
          dangerouslySetInnerHTML={{ __html: STRING.OUR_TECHNOLOGY }}
        />

        <div className="flex mb-[60px] flex-col">
          <div className="mb-[75px] sm:mb-[85px]">
            <h1
              className="text-[24px] sm:leading-[30px] mb-[18px] text-white leading-[25px] font-[700]"
              dangerouslySetInnerHTML={{
                __html: STRING.CONNECT_TO_BLOOMBERG_EMSX,
              }}
            />
            <span
              className="text-primary_color text-[16px]"
              dangerouslySetInnerHTML={{
                __html: STRING.EXECUTION_MANAGEMENT_SYSTEM_DEC,
              }}
            />
          </div>

          <div className="relative">
            <div className="relative flex items-center sm:justify-end back1 justify-center z-[2]">
              <img
                className="w-full"
                src={BASE_URL+"img/mobile_background.png"}
                alt="Background"
              />
              <img
                src={BASE_URL+"img/mobile_share.png"}
                alt="Share"
                className="absolute w-[278px] top-[-56px] right-[-17px] z-[-1]"
              />
              <img
                src={BASE_URL+"img/mobile_bloomberg.svg"}
                alt="Bloomberg"
                className="absolute sm:w-[83%] md:w-[83%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              />
            </div>
          </div>

        </div>

        <div className="flex  mb-[60px]  flex-col">
          <div className="mb-[75px]">
            <h1
              className="text-[24px] sm:leading-[30px] text-white leading-[25px] mb-[17px] font-bold"
              dangerouslySetInnerHTML={{
                __html: STRING.SIMPLIFY_YOUR_WORKFLOW,
              }}
            />
            <span
              className="text-primary_color text-[16px]"
              dangerouslySetInnerHTML={{
                __html: STRING.THE_BROKER_NEUTRAL_DEC,
              }}
            />
          </div>

          <div className="relative">
            <div className="relative back2 flex items-center justify-center sm:justify-start z-[2]">
              <img
                className="w-full"
                src={BASE_URL+"img/mobile_background2.png"}
              />
              <img
                src={BASE_URL+"img/mobile_share2.png"}
                alt="Share"
                className="absolute top-[-42px] left-[-17px] z-[-1]"
              />
              <img
                className="absolute sm:w-[83%] md:w-[83%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 img2"
                src={BASE_URL+"img/mobile_redilogo.svg"}
                alt="Bloomberg"
              />
            </div>
          </div>
        </div>
        <div className="flex mb-[60px] flex-col">
          <div className="mb-[75px]">
            <h1
              className="text-[24px] text-white leading-[25px] mb-[17px] font-bold"
              dangerouslySetInnerHTML={{
                __html: STRING.GET_EFFICIENT_EXECUTION,
              }}
            />
            <span
              className="text-primary_color text-[16px]"
              dangerouslySetInnerHTML={{
                __html: STRING.DAS_SYSTEMS_PROVIDE_DES,
              }}
            />
          </div>

          <div className="relative">
            <div className="relative back3 flex items-center justify-center sm:justify-end z-[2]">
              <img 
				src={BASE_URL+"img/mobile_background3.png"}
				className="w-full"
			  />
              <img
                src={BASE_URL+"img/mobile_share3.png"}
                alt="Share"
                className="absolute right-[-17px] sm:bottom-[-55px] bottom-[-75px] z-[1]"
              />
              <img
                className="absolute sm:w-[83%] md:w-[83%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                src={BASE_URL+"img/mobile_das.svg"}
                alt="Bloomberg"
              />
            </div>
          </div>
        </div>
      </div>

      {/* mobile view start */}
    </>
  );
};

export default OurTechnology;
