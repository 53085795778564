import React, { useEffect, useState } from "react";
import { STRING } from "../../config/string";
import ROUTE_URLS, { BASE_URL } from "../../config/routes";

const Header = ({setIsCommingSoon}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Toggle sidebar open/close state
  const toggleSidebar = () => setIsSidebarOpen(prev => !prev);

  // Close the sidebar
  const closeSidebar = () => setIsSidebarOpen(false);
  
  const fireComingSoon = () => { setIsSidebarOpen(false); setIsCommingSoon(true); };

  // Smoothly scroll to a specific section by ID
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`Element with id "${id}" not found.`);
    }
  };

  // Handle scroll event to scroll to a section and close the sidebar
  const handleScroll = (event, id) => {
    event.preventDefault();
    scrollToSection(id);
    closeSidebar();
  };

  useEffect(() => {
    // Function to log client heights of specified sections
    const handleScrollEvent = () => {
      const sections = ['technology', 'price', 'footer'];

      sections.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
          console.log(`Element ${id} clientHeight:`, element.clientHeight);
        } else {
          console.error(`Element with id "${id}" not found!`);
        }
      });
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScrollEvent);

    // Clean up scroll event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  return (
    <div className="bg-black shadow-header_shadow headerContainer lg:shadow-none xl:shadow-none 2xl:shadow-none z-20 mb-[30px] fixed md:relative lg:relative xl:relative 2xl:relative top-0 left-0 right-0 lg:px-0 xl:px-0 2xl:px-0 headerContainer sm:w-full md:w-full lg:w-[1080px]  m-auto py-[15px] px-[19px] md:px-[19px] sm:py-[48px] md:py-[48px] lg:py-[48px] xl:py-[48px] 2xl:py-[48px] justify-between flex items-center">

      {/* Logo */}
      <img alt="logo" onClick={() => window.location.reload()} className="cursor-pointer" src={BASE_URL+"img/OpalLogo.png"} />
 
      {/* Desktop Menu */}
      <div className="hidden sm:hidden md:flex lg:flex xl:flex 2xl:flex justify-between gap-[65px] contentContainer items-center">
        <div className="flex gap-5 ">
          <span className="text-primary_color hover:text-primary_active_color cursor-pointer" onClick={(e) => handleScroll(e, 'technology')}>
            {STRING.TECHNOLOGY}
          </span>
          <span className="text-primary_color hover:text-primary_active_color cursor-pointer" onClick={(e) => handleScroll(e, 'price')}>
            {STRING.PRICE}
          </span>
          <span className="text-primary_color hover:text-primary_active_color cursor-pointer" onClick={(e) => handleScroll(e, 'footer')}>
            {STRING.CONTACTS}
          </span>
        </div>

        <div className="flex gap-[9px] items-center">
          <a href={`tel:${STRING.USER_NUMBER}`} className="text-primary_active_color font-[700] text-[21px]">
            {STRING.USER_NUMBER}
          </a>
          {/*<button
            id="OPEN_AN_ACCOUNT"
            onClick={fireComingSoon} 
            className="relative btn1 text-white text-[18px] font-bold h-[50px] w-[180px] bg-primary_active_color rounded-full transition-background duration-300 hover:bg-primary_active_hover"
          >
            {STRING.OPEN_AN_ACCOUNT}
          </button>*/}
        </div>
      </div>

      {/* Mobile Menu Icon */}
      <img
        className="block menuIcon md:hidden sm:block lg:hidden xl:hidden 2xl:hidden cursor-pointer"
        src={BASE_URL+"img/menu.svg"}
        alt="mobile menu not found"
        onClick={toggleSidebar}
      />

      {/* Mobile Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full bg-black transition-transform duration-300 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} z-50 w-[300px]`}
      >
        <div className="flex flex-col gap-5 p-5 text-white">
          {/* Close Icon */}
          <img
            className="self-end cursor-pointer mb-5 w-10 h-10"
            src={BASE_URL+"img/close.svg"}
            alt="Close"
            onClick={closeSidebar}
          />
          <span className="cursor-pointer hover:text-primary_active_color" onClick={(e) => handleScroll(e, 'technology_mobile')}>{STRING.TECHNOLOGY}</span>
          <span className="cursor-pointer hover:text-primary_active_color" onClick={(e) => handleScroll(e, 'price')}>{STRING.PRICE}</span>
          <span className="cursor-pointer hover:text-primary_active_color" onClick={(e) => handleScroll(e, 'footer')}>{STRING.CONTACTS}</span>
		  {
			/*<button onClick={fireComingSoon} className="relative btn1 text-white text-[14px] font-semibold h-[40px] w-[180px] bg-primary_active_color rounded-full mt-5">
            {STRING.OPEN_AN_ACCOUNT}
          </button>*/ }
        </div>
      </div>

      {/* Drop Shadow Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-gray-800 opacity-70 z-40"
          onClick={closeSidebar}
        ></div>
      )}
    </div>
  );
};

export default Header;
